import { Helmet } from 'react-helmet'

import shh2023Gif from '../assets/images/shh2023.gif'
import Layout from '../components/Layout'
import styles from '../styles/pages/Poap.module.scss'

const Poap = () => (
  <Layout isRelativeFooter>
    <div className={styles.poap}>
      <Helmet>
        <title>0xHanson | POAP</title>
      </Helmet>
      <div className={styles.poap__grid}>
        <div className='grid'>
          <div className='grid__item'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              className='grid__item-cover'
              href='https://opensea.io/assets/matic/0xa39f2d874e8277e53e642491c6b4f99bb2855ecc/136'
            >
              <img src='https://i.seadn.io/s/raw/files/b70ed052a42a8539d25814821b4af4f8.png?auto=format&dpr=1&w=1000' alt='' loading='lazy' />
            </a>
            <p className='grid__item-label'>ETHTaipei 2024</p>
            <span className='grid__item-tag'>Polygon</span>
          </div>
          <div className='grid__item'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              className='grid__item-cover'
              href='https://opensea.io/assets/avalanche/0x32c9bb0c243874dbc2f13e392deb5a5e149f7169/41'
            >
              <img src='https://i.seadn.io/s/raw/files/d354f2ecaff4c832f0fe17ccdff36c2f.jpg?auto=format&dpr=1&w=750' alt='' loading='lazy' />
            </a>
            <p className='grid__item-label'>Taipei Blockchain Week 2023</p>
            <span className='grid__item-tag'>Avalanche</span>
          </div>
          <div className='grid__item'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              className='grid__item-cover'
              href='https://opensea.io/assets/matic/0xe65adb3652d149cfef5db4259d5e0e5375c6cb8f/6100'
            >
              <img src='https://i.seadn.io/s/raw/files/809723cdaaede455b452fc3f1e727b4f.gif?auto=format&dpr=1&w=750' alt='' loading='lazy' />
            </a>
            <p className='grid__item-label'>Taiwan Builder House 2023</p>
            <span className='grid__item-tag'>Polygon</span>
          </div>
          <div className='grid__item'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              className='grid__item-cover'
              href='https://solscan.io/tx/SGeDWRJ6PeopywG2NeDoVriVSC35oiuCtimhPCqM9zDZikNkB2AiSf7eZGKFSqBKDrk4vbRLSgkJyYTZf82W2sU'
            >
              <img src={shh2023Gif} alt='' loading='lazy' />
            </a>
            <p className='grid__item-label'>Solana Taipei Hacker House</p>
            <span className='grid__item-tag'>Solana</span>
          </div>
          <div className='grid__item'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              className='grid__item-cover'
              href='https://opensea.io/assets/matic/0xe114498c4da4a8f77d936b1e79cb5c9d690a65e9/28'
            >
              <img src='https://i.seadn.io/s/raw/files/7d7b27cadd0556e75a194f5a06d11434.png?auto=format&dpr=1&w=750' alt='' loading='lazy' />
            </a>
            <p className='grid__item-label'>Chain Holic Shi-Da</p>
            <span className='grid__item-tag'>Polygon</span>
          </div>
          <div className='grid__item'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              className='grid__item-cover'
              href='https://opensea.io/assets/matic/0xadd5ffcf1cd9e27c5fb7464162a4200c9cdb3646/178'
            >
              <img src='https://i.seadn.io/s/raw/files/cba6620a267f794c9e1e22a01207c568.jpg?auto=format&dpr=1&w=750' alt='' loading='lazy' />
            </a>
            <p className='grid__item-label'>Taipei Blockchain Week 2022</p>
            <span className='grid__item-tag'>Polygon</span>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Poap
