import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import ParticleAvatar from '../components/ParticleAvatar'
import styles from '../styles/pages/Home.module.scss'

const Home = () => (
  <Layout isLeftNavVisible>
    <div className={styles.home}>
      <Helmet>
        <title>0xHanson | Portfolio</title>
      </Helmet>
      <div className={styles.home__particle_avatar}>
        <ParticleAvatar />
      </div>
      <div className={styles.home__content}>
        <div className={styles.home__subheading}>Web3 Portfolio</div>
        <h1 className={styles.home__heading}>0xHanson</h1>
      </div>
    </div>
  </Layout>
)

export default Home
