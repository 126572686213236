import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { ReactComponent as SolanaIcon } from 'cryptocurrency-icons/svg/white/sol.svg'
import { ReactComponent as MaticIcon } from 'cryptocurrency-icons/svg/white/matic.svg'
import { ReactComponent as EthereumIcon } from 'cryptocurrency-icons/svg/white/eth.svg'

import Layout from '../components/Layout'
import styles from '../styles/pages/Projects.module.scss'
import FengLiPreviewImage from '../assets/images/fengli_preview.png'
import EggTWPreviewImage from '../assets/images/eggtw_preview.png'
import WoZhiDaoPreviewImage from '../assets/images/wozhidao_preview.png'

const Projects = () => {
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(undefined)

  const handleMouseOver = (url) => {
    setBackgroundImageUrl(url)
  }

  const handleMouseLeave = () => {
    setBackgroundImageUrl(undefined)
  }

  return (
    <Layout>
      <div className={styles.projects}>
        <Helmet>
          <title>0xHanson | Projects</title>
        </Helmet>
        <div
          className={styles.projects__menu_wrapper}
          style={{
            backgroundColor: backgroundImageUrl ? '#212529' : 'transparent',
            backgroundImage: `url(${backgroundImageUrl})`,
            backgroundSize: backgroundImageUrl === EggTWPreviewImage
              ? '100% 100%'
              : 'cover',
          }}
        >
          <ul className={styles.projects__menu}>
            <li
              onMouseOver={() => handleMouseOver(FengLiPreviewImage)}
              onMouseLeave={handleMouseLeave}
            >
              <button
                type='button'
                onClick={() => window.open('https://fengli-candy-machine-ui.vercel.app/')}
              >
                <SolanaIcon />
                <div>
                  <div>Feng-Li Candy Machine</div>
                  <div>Built at Solana Hacker House Taipei with 0xA2M</div>
                </div>
              </button>
            </li>
            <li
              onMouseOver={() => handleMouseOver(EggTWPreviewImage)}
              onMouseLeave={handleMouseLeave}
            >
              <button
                type='button'
                onClick={() => window.open('https://eggtw-portal.vercel.app/')}
              >
                <MaticIcon />
                <div>
                  <div>EggTW Meme Coin</div>
                  <div>Weekend Project with 0xA2M</div>
                </div>
              </button>
            </li>
            <li
              onMouseOver={() => handleMouseOver(WoZhiDaoPreviewImage)}
              onMouseLeave={handleMouseLeave}
            >
              <button
                type='button'
                onClick={() => window.open('https://devfolio.co/projects/wozhidao-dapp-6dbb')}
              >
                <EthereumIcon />
                <div>
                  <div>WOZHIDAO dApp</div>
                  <div>Ethereum Dev Bootcamp's Project</div>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default Projects
