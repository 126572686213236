import ParticleImage, { Vector, forces } from 'react-particle-image'
import useWindowSize from '@rooks/use-window-size'

const particleOptions = {
  filter: ({ x, y, image }) => {
    const pixel = image.get(x, y)
    return pixel.b > 50
  },
  color: () => '#8E76D5',
  radius: () => Math.random() * 1.5 + 0.5,
  mass: () => 40,
  friction: () => 0.15,
  initialPosition: ({ canvasDimensions }) => {
    return new Vector(canvasDimensions.width / 2, canvasDimensions.height / 2)
  }
}

const motionForce = (x, y) => {
  return forces.disturbance(x, y, 5)
}

const getScale = (innerWidth) => {
  if (innerWidth < 576) {
    return 1
  } else if (innerWidth < 768) {
    return 1.1
  } else if (innerWidth < 992) {
    return 1.2
  } else if (innerWidth < 1200) {
    return 1.3
  } else if (innerWidth < 1600) {
    return 1.5
  }
  return 1.8
}

const ParticleAvatar = () => {
  const { innerWidth, innerHeight } = useWindowSize()

  return (
    <ParticleImage
      src={'/0xhanson.png'}
      width={Number(innerWidth)}
      height={Number(innerHeight)}
      scale={getScale(innerWidth)}
      entropy={20}
      maxParticles={4000}
      particleOptions={particleOptions}
      mouseMoveForce={motionForce}
      touchMoveForce={motionForce}
      backgroundColor='transparent'
    />
  )
}

export default ParticleAvatar
